import React from "react";

// Components
import SecondaryLanding from "../../../../layouts/secondary-landing";
import SEO from "../../../../components/seo/seo";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import FaqAccordion from "../../../../components/faq/faq-accordion";

// Helpers
import getSortedFaqDataByCategory from "../../../../helpers/getSortedFaqDataByCategory";

const SpanishMortgageFAQ = () => {
  const { sortedFaqs, categoryFootnotes, schema } = getSortedFaqDataByCategory(["Spanish Home Loans Page"]);

  const title = "Préstamos hipotecarios preguntas frecuentes";
  const description =
    "Obtenga las respuestas que necesita a sus preguntas principales sobre hipotecas en nuestra sólida página de preguntas frecuentes sobre hipotecas y descubra si un préstamo hipotecario con WaFd Bank es adecuado para usted.";

  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-personal/prestamos-hipotecarios/preguntas-frecuentes"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ],
    schema
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/es",
      title: "Banca personal"
    },
    {
      id: 2,
      url: "/es/banca-personal/prestamos-hipotecarios",
      title: "Préstamos hipotecarios"
    },
    {
      id: 3,
      active: true,
      title: "Preguntas frecuentes"
    }
  ];

  const faqAccordionData = {
    id: "spanish-mortgage-faq-section",
    isSpanish: true,
    pageTitle: "Préstamos hipotecarios preguntas frecuentes",
    title: null,
    // pass faqData instead of faqCategoryNames
    faqData: {
      sortedFaqs,
      categoryFootnotes
    }
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <FaqAccordion {...faqAccordionData} />
    </SecondaryLanding>
  );
};

export default SpanishMortgageFAQ;
